import React from 'react';

import { useRouter } from 'next/router';
import Link from 'next/link';


export function BottomTabBar({
  children,
  ...props
}) {
  return (
    <div className="bottom-tab-bar" {...props}>
      {children}
    </div>
  )
}

export function BottomTabBarTabLink({
  children,
  href,
  ...props
}) {
  const router = useRouter()
  const isActive = router.asPath.indexOf(href) === 0
  return (
    <Link className={`bottom-tab-bar-tab-link ${isActive && 'bottom-tab-bar-link-active'}`} href={href} {...props}>
      {children}
    </Link>
  )
}