import React from 'react';

import { UserMenu } from './UserMenu';

export function NavMenuUserProfile({
    user,
    role,
    label
}) {
    return (
        <div className="nav-menu-user-profile">
            <div style={{ display: 'flex' }}>
                <UserMenu user={user} role={role} label={label} />
            </div>
        </div>
    )
}