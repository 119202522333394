import { useContext, useEffect, useState, createContext } from 'react';

import client from '@/client';

import { gql } from '@apollo/client';

import moment from 'moment';

import Link from 'next/link';

import { createTeleporter } from 'react-teleporter';

import { ThemeContext } from '@/contexts/ThemeContext';

import { NavMenu } from '@/components/NavMenu';
import { NavMenuSection } from '@/components/NavMenuSection';
import { NavMenuItem } from '@/components/NavMenuItem';
import { clarity } from 'react-microsoft-clarity';
import i18n from '../i18n';
import { LabelContext } from '@/contexts';
import { Disc, Money, House, List, Video, Megaphone, MusicNote, Toolbox, User, X, ChartLineUp } from '@phosphor-icons/react';

import { UserMenu } from '../components/UserMenu';
import { NavMenuUserProfile } from '../components/NavMenuUserProfile';
import { BottomTabBar, BottomTabBarTabLink } from '../components/BottomTabBar';

export const QUERY = gql`
  query getProfile($hostname: String) {
    me {
      imageUrl
      username
      roles {
        id
        role
        label {
          id
          name
          domain
          logotypeUrl
        }
        artists {
          id
          name
        }
      }
      label(domain: $hostname) {
        id
        name
        css
        domain
        logotypeUrl
        clarityId
        payeeUsers {
          payee {
            id
            name
          }
          user {
            id
            username
          }
        }
      }
    }
  }
`

export const DrawerContext = createContext({
  isRightDrawerOpen: false,
  setRightDrawerOpen: () => {}
})

export const TitleBar = createTeleporter()

export const ToolBar = createTeleporter()

export const PopupOverlay = createTeleporter({ multiSources: true })

export function DashboardLayout({
  children
}) {
  const [loading, setLoading] = useState(true)
  const { label, setLabel, role, setRole } = useContext(LabelContext)
  const { theme, setCSS, setTheme, setStyle } = useContext(ThemeContext)

  const [title, setTitle] = useState('')

  const [isRightDrawerOpen, setRightDrawerOpen] = useState(false)

  const [user, setUser] = useState(null)

  console.log("drawerContextProvider", DrawerContext.Provider)

  const handleToggleMenuOpen = (event) => {
    event.preventDefault()
    setRightDrawerOpen(!isRightDrawerOpen)
    return false
  }

  useEffect(() => {
    const domain = window.location.hostname.split(':')[0]
    client.query({
      query: QUERY,
      variables: {
        hostname: domain
      }
    }).then(({ data: { me }}) => {
      if (me.label) {
        setCSS(me.label?.css)
        setStyle(me.label?.style)
        setLabel(me.label) 
        setRole(me.roles.find((role) => role?.label.id === me.label.id))
        setLoading(false)
        setUser(me)
        if (me.label?.clarityId) {  
          clarity.init(me.label.clarityId);
          // Identify the user
          clarity.identify(me.username, { role: me.roles[0]?.role, label: me.label.name });
        }
      }
    })
  }, [])
  if (loading) {
    return <>Loading</>
  }

  console.log("children", children)

  if (label) {
    const navMenu = (
      <NavMenu>
        <NavMenuUserProfile user={user} role={role} label={label} />
        <NavMenuSection Icon={House} name={i18n.t('dashboard')}>
          <NavMenuItem Icon={House} href="/dashboard">{i18n.t('start')}</NavMenuItem>
          <NavMenuItem Icon={House} href={`/dashboard/statistics?start=${moment().subtract(28, 'days').format('YYYY-MM-DD')}&end=${moment().format('YYYY-MM-DD')}`}>{i18n.t('statistics')}</NavMenuItem>
        </NavMenuSection>
        {['OWNER', 'ADMIN'].indexOf(role?.role) !== -1 && (
          <NavMenuSection Icon={Toolbox} name={i18n.t('admin')}>
            <NavMenuItem Icon={User} href="/admin/artists">{i18n.t('artists')}</NavMenuItem>
            <NavMenuItem Icon={User} href="/admin/users">{i18n.t('users')}</NavMenuItem>
          </NavMenuSection>
        )}
        {user?.label.payeeUsers?.length > 0 && false && (
          <NavMenuSection Icon={Money} name={i18n.t('payments')}>
            {user?.label.payeeUsers.map((payeeUser) => (
              <NavMenuItem key={payeeUser.id} Icon={Money} href={`/dashboard/payees/${payeeUser.payee.id}`}>{payeeUser.payee.name}</NavMenuItem>
            ))}
          </NavMenuSection>          
        )}
        <NavMenuSection Icon={Disc} name={i18n.t('content')}>
          <NavMenuItem Icon={Disc} href="/dashboard/releases">{i18n.t('releases')}</NavMenuItem> 
          <NavMenuItem Icon={MusicNote} href="/dashboard/recordings">{i18n.t('recordings')}</NavMenuItem>
        </NavMenuSection>
        {/*<NavMenuSection Icon={Megaphone} name={i18n.t('learn')}>
          <NavMenuItem Icon={Video} href="/dashboard/academy">{i18n.t('assets')}</NavMenuItem> 
        </NavMenuSection>*/}
        <NavMenuSection Icon={Megaphone} name={i18n.t('promotion')}>
          <NavMenuItem Icon={Megaphone} href="/dashboard/pitch">{i18n.t('pitch')}</NavMenuItem> 
        </NavMenuSection>
      </NavMenu>
    )
    return (
      <DrawerContext.Provider value={{ isRightDrawerOpen, setRightDrawerOpen }}>
        <div className="app">
          <aside className={isRightDrawerOpen ? 'open' : ''}>
            <div className="heading">
              <div style={{ flex: 1 }}></div>
              <Link style={{ margin: 10 }} href="#" id="logo1" target="_blank" rel="noopener noreferrer">
                <img src={label?.logotypeUrl} height="64pt" />
              </Link>
              <div style={{ flex: 1 }} />
              <button onClick={handleToggleMenuOpen}  htmlFor="toggle-1" className="toggle-menu">
                {isRightDrawerOpen ?
                  <X size={28} /> : <List size={28} />
                }
              </button>
            </div>
            {navMenu}
            <div style={{ flex: 1 }}></div>
          </aside>
          <main>
            <div className="viewstack-header">
              <div className="container" style={{paddingTop: 0, paddingBottom: 0}}>
                <div className=" flex-row items-center">
                  <div>
                    <TitleBar.Target />
                  </div>
                  <div style={{ flex: 1 }}>
                    <ToolBar.Target />
                  </div>
                  <UserMenu user={user} role={role} label={label} />
                </div>
              </div>
            </div>
            <div className="viewstack">
              {children}
            </div>
            <div className="position-fixed top-0 w-100">
              <div className="main-nav bordered-top">
                <div className="container">
                  <div className="main-menu">
                    <Link href="#" id="logo1" target="_blank" rel="noopener noreferrer"><img src={label?.logotypeUrl} height="64pt" /></Link>
                    <div style={{ flex: 1}}></div>
                    <button onClick={handleToggleMenuOpen}  htmlFor="toggle-1" className="toggle-menu">
                      {isRightDrawerOpen ?
                        <X size={28} /> : <List size={28} />
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <BottomTabBar style={{ zIndex: 199}}>
              <BottomTabBarTabLink href="/dashboard">
                <House size={24} /> {i18n.t('start')}
              </BottomTabBarTabLink>
              <BottomTabBarTabLink href={`/dashboard/statistics?start=${moment().subtract(28, 'days').format('YYYY-MM-DD')}&end=${moment().format('YYYY-MM-DD')}`}>
                <ChartLineUp size={24} /> {i18n.t('stats')}
              </BottomTabBarTabLink>
              <BottomTabBarTabLink href="/dashboard/releases">
                <Disc size={24} /> {i18n.t('releases')}
              </BottomTabBarTabLink>
              <BottomTabBarTabLink href="/dashboard/account">
                <User size={24} /> {i18n.t('account')}
              </BottomTabBarTabLink>
            </BottomTabBar>
          </main>
          <div>
          <PopupOverlay.Target />
          </div>
        </div> 
      </DrawerContext.Provider> 
    ) 
  } else {
    return <>Error</>
  }
}

export default DashboardLayout;
