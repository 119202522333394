import React, { useEffect, useContext } from 'react';

import { getLabelByDomain } from '@/actions/login';
import { LabelContext } from '@/contexts';
import { ThemeContext } from '@/contexts/ThemeContext';
import moment from 'moment/moment';

export function AccountsLayout({ children }) {
    const { label, setLabel, setHostname } = useContext(LabelContext)
    const { theme, setCSS, setTheme, setStyle } = useContext(ThemeContext)
    useEffect(() => {
      const domain = window.location.hostname.split(':')[0]
      getLabelByDomain(domain).then((label) => {
        if (label) {
          setCSS(label?.css)
          setStyle(label?.style)
          setLabel(label)
          setHostname(domain)
        }
      })
    }, [])
    return (
      <ws-block>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ flex: 1 }}></div>
          <img src={label?.logotypeUrl} width="300pt" />
          <div style={{ flex: 1 }}></div>
          {children}
          <div style={{ flex: 1 }}></div>
          <small style={{ margin: 10, opacity: 0.5 }}>Copyright &copy; {moment().format('YYYY')} {label?.name}. All Rights Reserved.</small>
    
        </div>
      </ws-block>
    )
}