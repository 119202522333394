import React from 'react';

export function NavMenu({
    children,
    ...props
}) {
    return (
        <div className="nav-menu" {...props}>
            {children}
        </div>
    )
}