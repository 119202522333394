import React from 'react';

import { UserView } from './UserView';
import { DropDownMenuArea, DropDownMenu, DropDownMenuItem, DropDownMenuButton } from '@/components/DropDownMenu';

import i18n from '@/i18n'
import { DoorOpen, User } from '@phosphor-icons/react';

export function UserMenu({
    user,
    role,
    label
}) {
    return (
        <DropDownMenuArea>
            <DropDownMenuButton>
                <UserView user={user} role={role} label={label} />
            </DropDownMenuButton>
            <DropDownMenu>
                {/*<DropDownMenuItem Icon={User} href={`/dashboard/account`}>
                    {i18n.t('my-account')}
                </DropDownMenuItem>*/}
                <DropDownMenuItem Icon={DoorOpen} href={`/accounts/logout`}>
                    {i18n.t('logout')}
                </DropDownMenuItem>
            </DropDownMenu>
        </DropDownMenuArea>
    )
}