import React from 'react';

import NoSSR from 'react-no-ssr';

import App from '@/App';

import '@/styles/cssvars.css';
import '@/styles/aloader.scss';
import '@/styles/audio-player.css';

export function AppPage({ Component, pageProps }) {
  return (
    <NoSSR>
      <App Component={Component} pageProps={pageProps} />
    </NoSSR>
  )
}

export default AppPage;