import { gql } from "@apollo/client";

export const USER_FIELDS = gql`
  fragment UserFields on AloaderUserEntity {
    username
    imageUrl
  }
`


export const NODE_FIELDS = gql`
fragment NodeFields on AloaderNodeInterface {
  id
}
`

export const ENTITY_FIELDS = gql`
fragment EntityFields on AloaderEntityInterface {
  ...NodeFields
  name
}
`

export const LABEL_FIELDS = gql`
  fragment LabelFields on AloaderLabelEntity {
    ...EntityFields
    domain
    css
  }
`


export const ARTIST_FIELDS = gql`
  fragment ArtistFields on AloaderArtistEntity {
    ...EntityFields
  }
`


export const RELEASE_FIELDS = gql`
  fragment ReleaseFields on AloaderReleaseEntity {
    ...EntityFields
    id
    name
    released
  }
`

export const RECORDING_FIELDS = gql`
  fragment RecordingFelds on AloaderRecordingEntity {
    ...EntityFields
    isrc
  }
`


export const TRACK_FIELDS = gql`
  fragment TrackFelds on AloaderTrackEntity {
    ...EntityFields
    recording {
      ...RecordingFields
    }
    release {
      ...ReleaseFields
    }
  }
`

export const fragments = gql`
${NODE_FIELDS}
${ENTITY_FIELDS}
${LABEL_FIELDS}
${ARTIST_FIELDS}
${RELEASE_FIELDS}
`

export default fragments;