import React from 'react';

export const PlayerContext = React.createContext({
    recording: null,
    pause: () => {},
    resume: () => {},
    status: 'stopped',
    setRecording: () => {}
})


