import React, { useState, useEffect, createContext } from 'react';

import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';              
import { ApolloProvider } from '@apollo/client';

import DashboardLayout from '@/layouts/DashboardLayout';
import { SnackbarProvider } from 'notistack'
import { AppContext } from '@/contexts/AppContext';
import { SessionContext } from '@/contexts/SessionContext';
import { LabelContext } from '@/contexts';
import { Checkbox, TextField, ThemeProvider, createTheme } from '@mui/material';

import client from '@/client';

import "react-datepicker/dist/react-datepicker.css";

import { ThemeContext } from './contexts/ThemeContext';
import { PlayerContext } from './contexts/PlayerContext';

import { getSession, startLogin } from '@/actions/login';
import { useRef } from 'react';
import { AccountsLayout } from './layouts/AccountsLayout';
import {BrowserView, MobileView} from 'react-device-detect';
const delimiters = [188, 13];

const lightTheme = createTheme({
  palette: {  
    mode: 'light',
  },
});
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App({ Component, pageProps }) {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([])
  const [session, setSession] = useState(null)
  const [label, setLabel] = useState(null)
  const [status, setStatus] = useState(null)
  const [role, setRole] = useState(null)
  const play = () => {
    if (audioEl?.current) {
      audioEl.current.play()
    }
  }
  const pause = () => {
    if (audioEl?.current) {
      audioEl.current.pause()
    }
  }

  const [hostname, setHostname] = useState('localhost:3000')
  const router = useRouter()

  const [recording, setRecording]  = useState(null)
  
  const showMessage = (message) => {
    setMessages([...messages, message])
  }
  const [loading, setLoading] = useState(true)

  const getLayout = Component.getLayout || ((page) => page);

  const audioEl = useRef(null)

  const pathname = router.asPath
  useEffect(() => {
    const search = new URLSearchParams(location.search)
    if (pathname.indexOf('/accounts') === 0 || pathname === '/' || pathname.indexOf('/invitation') === 0) {
      return
    }
    if (!search.has('code')) {
      getSession().then(value => {
        if (!value) {
          startLogin()
          return
        }
        setSession(value)
        setLoading(false)
      }).catch(e => {
        console.error(e)
      })
    }
       
    if (typeof window !== 'undefined') {
      setHostname(window.location.hostname);
    }
  }, [])
  

  const [prefersDarkMode, setPrefersDarkMode] = useState(false)

  const [theme, setTheme] = useState('light')

  const setStyle = (values) => {
    /*Object.keys(values ?? {}).map((prop) => {
      document.documentElement.style.setProperty(`--${prop}`, values[prop])  
    })*/
  }

  const setCSS = (css) => {
    let labelCSS = document.querySelector("#labelCSS")
    if (!labelCSS) {
      labelCSS = document.createElement('style')
      document.head.appendChild(labelCSS)
    }
    labelCSS.innerHTML = css
  }

  useEffect(() => {
    let realTheme = theme
    if (!realTheme) {
      realTheme = prefersDarkMode ? 'dark' : 'light'
    }
  
    document.body.style.backgroundColor = realTheme === 'dark' ? 'rgba(10, 10, 10, 1)' : ''
    document.body.style.color = realTheme === 'dark' ? 'rgba(255, 255, 255, .8)' : 'rgba(0, 0, 0, .9)'
  }, [theme, prefersDarkMode])

  return ( 
      <ApolloProvider client={client}>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
              <ThemeContext.Provider value={{ theme, setCSS, setTheme, setStyle }}>
                <AppContext.Provider>
                  <PlayerContext.Provider value={{ play, pause, status, recording, setRecording }}>
                    <LabelContext.Provider value={{ role, setRole, label, setLabel, hostname, setHostname }}>
                      <SessionContext.Provider value={{ session, setSession }}>
                        <BrowserView>
                          {pathname.indexOf('/accounts') === 0 || pathname.indexOf('/invitation') === 0 || pathname === '/' ? (
                            <AccountsLayout label={label}>
                              {getLayout(<Component {...pageProps} />)}
                            </AccountsLayout>
                          ) : (pathname.indexOf('/dashboard') === 0) || (pathname.indexOf('/admin') === 0) ? (
                            <DashboardLayout label={label}>
                              {getLayout(<Component {...pageProps} />)}
                            </DashboardLayout>
                          ) : getLayout(<Component {...pageProps} />)}
                          {recording && (
                            <div className="player">
                              <audio onPause={() => setStatus('paused')} onPlay={() => setStatus('playing')} ref={audioEl} src={recording.audioUrl} controls></audio>
                            </div>
                          )}
                        </BrowserView>
                        <MobileView style={{ display: 'flex', alignItems: 'center', textAlign: 'center', padding: '5rem', justifyContent: 'center', position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh' }}>
                          <h1>Please access the service from a desktop computer</h1>
                        </MobileView>
                      </SessionContext.Provider>
                    </LabelContext.Provider>
                  </PlayerContext.Provider>
                </AppContext.Provider>
              </ThemeContext.Provider>
            </ThemeProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </ApolloProvider> 
  );
}

export default App;