import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { createFragmentRegistry } from '@apollo/client/cache';

import { setContext } from '@apollo/client/link/context';
import localForage from 'localforage';
import { getAPIUrl } from '@/utils'
import axios from 'axios'

import fragments from './fragments';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

const initCache = (initialState) => {
  const cache = new InMemoryCache({
    fragments: createFragmentRegistry(
      fragments
    )
  }).restore(initialState || {});
  
  /**
   * Cache uses localStorage to save data.
   * 
   * This cache is used by Apollo (graphql client).
   */
  if(typeof window !== 'undefined') {
    persistCache({
      cache,
      storage: window.localStorage
    });
  }
  
  return cache;
}

const cache = new InMemoryCache({
  typePolicies: {
    keyFields: false
  }
})

export const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

export const httpLink = createHttpLink({
  uri: `${getAPIUrl()}/graphql/`,
});
export const authorizedFetch = async (method, path) => {
  return await fetch(`${getAPIUrl()}/api${path}`, {
    method,
    headers: {
      authorization: `JWT ${await localForage.getItem('token')}`
    }
  })
}
export const restFetcher =  async (path) => {
  const token = await localForage.getItem('token');
  const url = `${getAPIUrl()}/api${path}`;
  return axios.get(url, {
    withCredentials: true,
    headers: {
      authorization: token ? `JWT ${token}` : "",
    }
  })
}
 
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await localForage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    }
  }
});

export const client = new ApolloClient({
  defaultOptions: {
    fetchPolicy: 'no-cache',
    //nextFetchPolicy: 'cache-first',
    //errorPolicy: 'all'
    query: {
    }
  },
  link: link.concat(authLink.concat(httpLink)),
  cache
});

export default client
