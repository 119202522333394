import React from 'react';

import { useMediaQuery } from '@uidotdev/usehooks'

export function UserView({
    user,
    role,
    label,
    expanded=false
}) {
  const isMobile = useMediaQuery('screen and (max-width: 600px)')
    return (
        <div className="flex flex-row items-center">
            <img style={{ borderRadius: '120pt', aspectRatio: '1/1', height: '32pt'}} src={user.imageUrl} />
            {!isMobile && expanded && <div className="flex flex-col" style={{ gap: 1}}>
                <span style={{ fontWeight: 'bold' }} className="text-xs">{role?.artists?.map(a => a.name)}</span>
                <span style={{ opacity: 0.5, fontSize: '0.8rem' }} className="text-xs">{role?.role} <span style={{fontFamily: 'monospace'}}>·</span> {label?.name}</span>
            </div>}
          
        </div>
    )
}