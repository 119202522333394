import React, { useContext } from 'react';

import { useRouter } from 'next/router';

import Link from 'next/link';
import { DrawerContext } from '../layouts/DashboardLayout';

export function NavMenuItem({
    children, 
    Icon,
    ...props
}) {
    const {
        setRightDrawerOpen
    } = useContext(DrawerContext)
    const router = useRouter()
    const isActive = router.asPath.indexOf(props.href) === 0
    return (
        <Link onClick={() => setRightDrawerOpen(false)} className={`nav-menu-item ${isActive && 'nav-menu-item-active'}`} {...props}> 
            <Icon size={28} /> {children} 
        </Link>
    )
}