import React, { useState, createContext, useContext, useRef } from 'react';

import { Cube } from '@phosphor-icons/react';

import { CSSTransition } from 'react-transition-group'

import { Button } from './Button';
import Link from "next/link";


export const DropDownContext = createContext({
    open: false,
    setOpen: (value) => {}
})

export function DropDownMenuButton({
    children,
    ...props
}) {
    const { open, setOpen } = useContext(DropDownContext)
    const handleDropDownClick = (event) => {
        setOpen(!open)
    }
    return (
        <Button variant="default" onClick={handleDropDownClick} {...props}>{children}</Button>
    )
}

export function DropDownMenuArea({
    children
}) {
    const [open, setOpen] = useState(false)

    console.log("children", children)
    return (
        <DropDownContext.Provider value={{ open, setOpen }}>
            <>
        
                <div className="drop-down-menu-area">
                    {children}
                </div>
            </>
        </DropDownContext.Provider>
    )
} 
export function DropDownMenu({
    children,
    Icon=Cube
}) {
    const rootNodeRef = useRef(null)
    const { open, setOpen } = useContext(DropDownContext)
    return (
        <CSSTransition nodeRef={rootNodeRef} in={open} timeout={200} classNames="fade-transition">
            <div ref={rootNodeRef} className="drop-down-menu">
                {children}
            </div>
        </CSSTransition>
    )
}
export function DropDownMenuItem({
    children,
    Icon=Cube,
    ...props
}) {
    return (
        <Link {...props} className="drop-down-menu-item">
            <Icon size={28} />
            <div>{children}</div>
        </Link>
    )
}