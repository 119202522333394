import { CheckCircle, XCircle } from '@phosphor-icons/react';
import Link from 'next/link';
import React from 'react';

import { MoonLoader } from 'react-spinners'

export function Button({
  children,
  variant,
  disabled,
  color,
  type,
  loadingText='loading',
  successText='success',
  errorText='error',  
  loading=false,
  error=false,
  success=false,
  onClick,
  component,
  title,
  ...props
}) {
  let content = children
  let Component = 'a'
  if (props.href) {
    Component = Link
  }
  if (type === 'submit') {
    Component = 'button'
  }
  if (component) {
    Component = component
  }

  const handleClick = (event) => {
    if (!onClick) {
      return
    }
    if (type !== "submit") {
      event.preventDefault()
    }
    if (disabled) {
      return
    }
    onClick(event)
    return false
  }
  if (error) {
    content = (
      <>
        <XCircle />
        {errorText}
      </>
    )
  } else if (success) {
    content = (
      <>
        <CheckCircle /> 
        {successText}
      </>
    )
  } else if (loading) {
    content = (
      <>
        <MoonLoader disabled={disabled} width={20} color="currentColor" size={15} />
        {loadingText}
      </>
    )
  }
  return (
    <Component title={title} disabled={disabled} type={type} style={{ flexDirection: 'row', display: 'flex', gap: 10}} className={`button button-${color} button-${variant} ${disabled && 'button-disabled'} ${loading && 'button-loading'} ${success && 'button-success'} ${error && 'button-error'}`} {...props} onClick={handleClick}>{content}</Component>
  )
}