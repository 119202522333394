import React from 'react';

export function NavMenuSection({
    children,
    name,
    Icon,
    ...props
}) {
    return (
        <div className="nav-menu-section" {...props}>
            <div className="nav-menu-section-header">
               {name}
            </div>
            <div className="nav-menu-section-items">
                {children}
            </div>
        </div>
    )
}